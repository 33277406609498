import React from 'react';
import TermsSection from '../components/sections/TermsSection';
import FlexBackground from '../components/backgrounds/FlexBackground';

const PrivacyPolicy = () => {
    return (
        <FlexBackground>
            <TermsSection>
                <h1>Privacy Policy</h1>
                <p>Last Updated: Aug 19, 2024</p>

                <br></br>

                <h2>Welcome to CognideX</h2>
                <p>
                    At CognideX ("we," "our," "us," or "CognideX"), your privacy is of the utmost importance. This Privacy Policy outlines how we collect, use, process, store, and disclose your personal information when you visit our website https://www.cognidex.ai (the "Website") and use our platform and services (collectively, the "Services").
                </p>
                <p>
                    CognideX is a decentralized data marketplace that empowers individuals to monetize their personal data by contributing it to our Data Pool. By using our Services, you consent to the collection, use, processing, storage, and disclosure of your personal information as set out in this Privacy Policy.
                </p>

                <br></br>

                <h2>1. When We Collect Information</h2>
                <p>
                    We may collect personal information when you:
                    <ul>
                        <li>Register or sign up for our Services</li>
                        <li>Use our Services</li>
                        <li>Visit our Website</li>
                        <li>Contact us</li>
                        <li>Provide us with your consent in other interactions</li>
                    </ul>
                </p>

                <br></br>

                <h2>2. Types of Information We Collect</h2>

                <h3>2.1 Information You Provide</h3>
                <p>
                    - <strong>Contact Information:</strong> Includes your name, email address, and other details provided during account creation.<br />
                    - <strong>Data Contributions:</strong> Information you choose to upload to our Data Pool, which may include personal data from platforms like LinkedIn, TikTok, Spotify, and YouTube.<br />
                    - <strong>Payment Information:</strong> For transactions involving CGDX, we may collect payment-related information via Ethereum-compatible wallets.
                </p>

                <br></br>

                <h2>3. How We Use Your Information</h2>
                <p>
                    We use your personal information to:
                    <ul>
                        <li>Provide and improve our Services, including managing your account and processing Data Pool contributions</li>
                        <li>Communicate with you, including updates and newsletters</li>
                        <li>Comply with legal obligations and enforce agreements</li>
                        <li>For targeted marketing purposes, using your email address and other information</li>
                    </ul>
                </p>

                <br></br>

                <h2>4. How We Disclose Your Information</h2>

                <h3>4.1 To Buyers</h3>
                <p>
                    We disclose data you contribute to the Data Pool to Buyers, with all data masked and anonymized to protect your identity. Certain personal information, like your email address, may be retained for marketing purposes.
                </p>

                <h3>4.2 To Service Providers</h3>
                <p>
                    We may share information with third-party providers that assist us in platform operation, transaction processing, and marketing. These providers are bound by confidentiality obligations.
                </p>

                <h3>4.3 For Legal Reasons</h3>
                <p>
                    We may disclose your information if required by law or in response to requests from public authorities.
                </p>

                <h3>4.4 In Business Transfers</h3>
                <p>
                    In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.
                </p>

                <br></br>

                <h2>5. Your Privacy Choices</h2>
                <p>
                    You have the right to:
                    <ul>
                        <li><strong>Access and Update:</strong> Update your personal information via your account</li>
                        <li><strong>Delete:</strong> Delete your account and data at any time</li>
                        <li><strong>Withdraw Consent:</strong> Withdraw consent for data processing</li>
                        <li><strong>Opt-Out of Marketing:</strong> Opt-out of marketing emails by unsubscribing</li>
                    </ul>
                </p>

                <br></br>

                <h2>6. Security</h2>
                <p>
                    We implement appropriate measures to protect your information from unauthorized access, though no system can guarantee absolute security.
                </p>

                <br></br>

                <h2>7. Retention</h2>
                <p>
                    We retain your information only as long as necessary to fulfill the purposes outlined in this Privacy Policy, though anonymous data may continue to be retained.
                </p>

                <br></br>

                <h2>8. International Transfers</h2>
                <p>
                    Your information may be transferred to servers outside of your country. By using our Services, you consent to such transfers.
                </p>

                <br></br>

                <h2>9. Children's Privacy</h2>
                <p>
                    Our Services are not intended for individuals under 18. If we become aware of any such data, we will delete it.
                </p>

                <br></br>

                <h2>10. Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. Continued use of our Services after changes implies acceptance of the revised policy.
                </p>

                <br></br>

                <h2>11. Contact Us</h2>
                <p>
                    If you have any questions or concerns about this Privacy Policy, please contact us at info@cognidex.ai.
                </p>
            </TermsSection>
        </FlexBackground>
    );
};

export default PrivacyPolicy;
