import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CountdownClock = styled.h5`
    font-size: 10vw;
    color: white;
    margin: 0;
    font-weight: 600;
    text-align: center;
    font-family: 'Modernist', sans-serif;
    letter-spacing: 0.1rem;
    text-shadow: 0 0 10px rgba(0, 255, 0, 0.75), 0 0 20px rgba(0, 255, 0, 0.5); /* Neon glow effect */

    @media (max-width: 768px) {
        font-size: 8vw;
    }
`;

const Colon = styled.span`
    color: white;
    text-shadow: 0 0 10px rgba(0, 255, 0, 0.75), 0 0 20px rgba(0, 255, 0, 0.5); /* Matching glow effect */
`;

const CountdownTimer = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date('November 30, 2024 23:59:59') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = null;
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    if (!timeLeft) {
        return <div>The initial rewards period has ended.</div>;
    }

    return (
        <CountdownClock>
            <Colon>-&nbsp;</Colon>
            {String(timeLeft.days).padStart(2, '0')}d
            <Colon>:</Colon>
            {String(timeLeft.hours).padStart(2, '0')}h
            <Colon>:</Colon>
            {String(timeLeft.minutes).padStart(2, '0')}
            <Colon>:</Colon>
            {String(timeLeft.seconds).padStart(2, '0')}
            <Colon>&nbsp;-</Colon>
        </CountdownClock>
    );
};

export default CountdownTimer;
