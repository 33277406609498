import React from 'react'
import styled from 'styled-components'


// Essentially the Hero section
const Container = styled.div`
    position: relative;
    height: auto;
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: calc(10vh + 70px) 5vw 20px 5vw;
    gap: 20px;

    h1 {
        font-size: 4rem;
    }

    h2 {
        font-size: 2rem;
        color: white;
        margin: 0;
    }

    h3 {
        font-size: 1.5rem;
        color: white;
        margin: 0;
    }

    p {
        font-size: 1.2rem;
        color: white;
        margin: 0;
        line-height: 1.4;
    }

    .time-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;    
        justify-content: center;
        gap: 5px;
        width: 90vw;
        height: 100%;

        h1 {
            text-align: center;
            
            @media (max-width: 768px) {
                font-size: 2.4rem;
            }

            @media (max-width: 500px) {
                font-size: 2rem;
            }
        }

        .button-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            margin: 30px 0 0 0;
            justify-content: center;
            gap: 12px;
            width: auto;
            height: auto;
        }
    }

    .logo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        width: auto;
        height: 70px;

        .divider {
            width: 3px;
            height: 30px;
            background-color: white;
        }

        h4 {
            font-size: 1.6rem;
            font-weight: 600;
            white-space: nowrap;
        }

        img {
            width: 180px;
            height: auto;
        }

        .inner-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            width: 100%;
        }
    }

    @media (max-width: 1000px) {
        h1 {
            font-size: 3rem;
        }

        h2 {
            font-size: 1.8rem;
        }

        h3 {
            font-size: 1.2rem;
        }

        p {
            font-size: 1rem;
        }

        .logo-container {
            h4 {
                font-size: 1.4rem;
            }

            img {
                width: 120px;
            }
        }
    }

    @media (max-width: 768px) {
        gap: 10px;

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 1.6rem;
        }

        h3 {
            font-size: 1.2rem;
            text-align: left;
        }

        p {
            font-size: 1rem;
        }

        .logo-container {
            flex-direction: column;
            gap: 0px;

            .divider {
                display: none;
            }

            h4 {
                font-size: 1.2rem;
            }

            img {
                width: 100px;
            }
        }
    }
`;

const TermsSection = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default TermsSection
