import React from 'react';
import DGPTBackground from '../components/backgrounds/DGPTBackground';
import AboutUsSection from '../components/sections/AboutUsSection';
import CountdownTimer from '../components/countdown/CountdownTimer';

import MainButton from '../components/buttons/MainButton';

const EarlyBirdPage = () => {
    return (
        <DGPTBackground>
            <AboutUsSection>
                <div className="time-container">
                    <CountdownTimer />
                    <h1>
                        Supercharge your data,<br></br>
                        10x your Contribute Now!
                    </h1>
                    <div className="button-container">
                        <MainButton header="Contribute" subheader="Earn 10x rewards" onClick={() => window.open('/datapool?role=contribute', '_self')} />
                        <MainButton header="Learn More" subheader="About Early Bird" onClick={() => window.open('https://docs.cognidex.ai/Events&Programs/early-contributors', '_blank')} />
                        <MainButton header="Tutorial" subheader="How to Contribute" onClick={() => window.open('https://docs.cognidex.ai/Learn/contribute-data-pool', '_blank')} />
                    </div>
                </div>
            </AboutUsSection>
        </DGPTBackground>
    );
};

export default EarlyBirdPage;
