import React from 'react'
import AboutUsSection from '../components/sections/AboutUsSection'
import DGPTBackground from '../components/backgrounds/DGPTBackground'
import PolkadotLogo from '../assets/logo/polkadot-logo.svg';
import MoonbeamLogo from '../assets/logo/moonbeam-logo.svg';

const AboutUs = () => {
    return (
        <DGPTBackground>
            <AboutUsSection>
                <h1>
                    CognideX
                </h1>
                <h3>
                    Own your data, shape the future.
                </h3>
                <p>
                    CognideX is a decentralized data marketplace built on the Polkadot ecosystem that incentivizes users for the high-quality data they provide. Users contribute to data pools and receive rewards in our native token, CGDX, based on the quality of their submissions. What sets us apart from other data marketplaces is our use of blockchain technology to verify each transaction, ensuring that all collected data is authentic and reliable. We prioritize user privacy by masking personal identifiable information (PII) and assigning each user an anonymous unique identifier hash.
                    <br></br>
                    <br></br>
                    We merge individual data pools into a single, comprehensive Data Pool containing all user-uploaded data. This consolidation is achieved using cutting-edge AI technology while maintaining each user's unique identifier hash. With this extensive Data Pool, we can easily provide valuable insights into target markets.
                    <br></br>
                    <br></br>
                    In addition to user-contributed data, CognideX features access to 22,000 datasets from across the internet. Our smart assistant, powered by Large Language Model (LLM) agents, seamlessly fetches these datasets, enabling users to search for datasets based on specific use cases. By leveraging LLMs, users can generate crystal-clear insights through any query, accessing information from an agent that has in-depth knowledge of both user-uploaded data and external datasets.
                </p>
                <div className='logo-container'>
                    <div className='inner-container'>
                        <h4>
                            Built on:
                        </h4>
                        <img src={MoonbeamLogo} alt='Moonbeam Logo' />
                    </div>
                    <div className='divider'></div>
                    <div className='inner-container'>
                        <h4>
                            Secured by:
                        </h4>
                        <img src={PolkadotLogo} alt='Polkadot Logo' />
                    </div>
                </div>
            </AboutUsSection>
        </DGPTBackground>
    )
}

export default AboutUs
