import React from 'react'

import MainBg from '../components/backgrounds/MainBg'
import GradientBg from '../components/backgrounds/GradientBackground'
import FirstSection from '../components/sections/FirstSection'
import SecondSection from '../components/sections/SecondSection'

import MainButton from '../components/buttons/MainButton'
import FeatureCard from '../components/cards/FeatureCard'
import RowFeatureCard from '../components/cards/RowFeatureCard'

import MainSlider from '../components/slider/MainSlider'

import CognideXLogo from '../assets/logo/cognidex-logo.svg';
import ThirdSection from '../components/sections/ThirdSection'
import ForthSection from '../components/sections/ForthSection'

const Home = () => {
    const cardData = [
        {
            logo: CognideXLogo,
            title: "Utility",
            description: <>The CGDX token is the UTILITY token for the DATAPOOL</>,
        },
        {
            logo: CognideXLogo,
            title: "Buy and Sell",
            description: <>Buy and Contribute data using the CGDX token</>,
        },
        {
            logo: CognideXLogo,
            title: "Data Monetization",
            description: <>Monetize your data and GET PAID in CGDX</>,
        },
    ];

    const open_new_tab = (url) => {
        window.open(url, '_blank');
    }

    return (
        <MainBg>
            <GradientBg>
                <FirstSection>
                    <div>
                        <h4>
                            CognideX Decentralized Data Exchange
                        </h4>
                        <h1>
                            My Data,
                        </h1>
                        <h1>
                            My Control!
                        </h1>
                    </div>
                    <h4>
                        Start contributing to the DataPool and Monetize your data today<br></br>
                        Multiply your earnings by contributing early! <a href='https://docs.cognidex.ai/Events&Programs/early-contributors' target='_blank' rel='noreferrer'>Learn more</a>
                    </h4>
                    <div className='button-container'>
                        <div className='button-container'>
                            <MainButton header='Contribute' subheader={<>Earn CGDX <br></br>by uploading your data</>} onClick={() => window.location.href = '/datapool?role=contribute'} />
                        </div>
                        <div className='button-container'>
                            <MainButton header='Tutorials' subheader={<>How to start <br></br>contributing and earning</>} onClick={() => open_new_tab('https://docs.cognidex.ai/Learn/contribute-data-pool')} />
                        </div>
                    </div>
                </FirstSection>
                <ThirdSection>
                    <h1>
                        Find Data To<br></br> Power Business Solutions
                    </h1>
                    <h4>
                        Search for Data, Contribute Data and Buy Data <br></br>from millions of Data Contributors to power innovate business solutions
                    </h4>
                    <div className='card-collection'>
                        <FeatureCard logo={CognideXLogo} title="DataGPT (D-GPT)" description={<>Find Data that REALLY matters to you. Search with use cases and get the data you need - FAST!</>} onClick={() => window.location.href = '/D-GPT'} />
                        <FeatureCard logo={CognideXLogo} title="Data Pool" description={<>Share your valuable data with the DataPool and turn it into profit!</>} onClick={() => window.location.href = '/datapool'} />
                    </div>
                </ThirdSection>
                <SecondSection>
                    <h1>
                        GENERATE INSIGHTS TO PROMOTE BUSINESS
                    </h1>
                    <h4>
                        Generative Business Insights using our latest Generative AI agent <br></br>and market your business and products to millions of clients.
                    </h4>
                    <div className='card-collection'>
                        <FeatureCard logo={CognideXLogo} title="InsightGPT (I-GPT)" description={<>Try our newest agent to generate actionable insights for your business, products and more.</>} />
                        <FeatureCard logo={CognideXLogo} title="Learn More" description={<>Read our documentation on how I-GPT can help you generate immediate insights, prompting practices and more,</>} />
                    </div>
                </SecondSection>

                <ForthSection>
                    <div className='card-collection'>
                        {cardData.map((card, index) => (
                            <div className='card' key={index}>
                                <FeatureCard
                                    logo={card.logo}
                                    title={card.title}
                                    description={card.description}
                                />
                            </div>
                        ))}
                    </div>
                    <div className='card-slider'>
                        <MainSlider slideWidth={100}>
                            {cardData.map((card, index) => (
                                <RowFeatureCard
                                    key={index}
                                    logo={card.logo}
                                    title={card.title}
                                    description={card.description}
                                    direction='left'
                                    minHeight='300px'
                                />
                            ))}
                        </MainSlider>
                    </div>
                    <h1>
                        SYNERGY OF <br></br>AI & BLOCKCHAIN
                    </h1>
                    <h4 id="footer">
                        A token that emcompasses data like never before, buying and selling data all using CGDX
                    </h4>
                </ForthSection>
            </GradientBg>
        </MainBg>
    )
}

export default Home
