import React from 'react'
import DGPTBackground from '../components/backgrounds/DGPTBackground'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;  
    width: 100vw;

    p {
        color: white;
        font-size: 2rem;
        margin: 0;
        font-weight: 600;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }

        @media (max-width: 500px) {
            font-size: 1.2rem;
        }
    }
    
    
    a {
        color: white;
        font-size: 2rem;
        margin: 0;
        font-weight: 600;
        transition: color 0.3s ease;

        @media (max-width: 768px) {
            font-size: 1.5rem;
        }

        @media (max-width: 500px) {
            font-size: 1.2rem;
        }

        &:hover {
            color: var(--primary-color);
        }
    }
`

const Error = () => {
    return (
        <DGPTBackground>
            <Container>
                <h1>404</h1>
                <p>Lost in the vast sea of Data?</p>
                <a href="/">
                    Don't worry, we'll take you back home
                </a>
            </Container>
        </DGPTBackground>
    )
}

export default Error
