import React from 'react';
import TermsSection from '../components/sections/TermsSection';
import FlexBackground from '../components/backgrounds/FlexBackground';

const TermsCondition = () => {
    return (
        <FlexBackground>
            <TermsSection>
                <h1>Terms of Service</h1>
                <p>Last Updated Date: 10/28/2024</p>

                <h2>Welcome to CognideX</h2>
                <p>
                    CognideX is a data and technology platform operated by the research team at the National University of Singapore (NUS). Our mission is to establish an ethical data marketplace where users can contribute their personal data for monetization and businesses can access unparalleled data insights.
                </p>
                <p>
                    <strong>BY CLICKING “I ACCEPT” (OR SIMILAR), REGISTERING AN ACCOUNT, BROWSING OUR WEBSITE, OR OTHERWISE ACCESSING OUR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS.</strong>
                </p>

                <br></br>

                <h2>1. Description of the Services</h2>

                <h3>1.1 Creating a CognideX Account</h3>
                <p>
                    To utilize the Services, you must create a CognideX Account and may need to provide personal information, handled per our Privacy Policy at https://www.cognidex.ai/privacy. You are responsible for the confidentiality of your login credentials.
                </p>

                <h3>1.2 Accessing the Services</h3>
                <p>
                    The CognideX Services are accessible only via the Platform, currently limited to legal residents of Singapore and provided in English. Users must be at least 18 years old.
                </p>

                <h3>1.3 Data Pool; Connections; Sharing</h3>
                <p>
                    CognideX operates a Data Pool where users can contribute data, aggregated for monetization and insights. Users connect third-party platforms to contribute data, which is anonymized.
                </p>

                <br></br>

                <h2>2. Use of the Service</h2>

                <h3>2.1 Website License</h3>
                <p>
                    CognideX grants you a non-transferable, non-exclusive, revocable license to use the Website for personal purposes, subject to these Terms.
                </p>

                <h3>2.2 Compliance and Restrictions</h3>
                <p>
                    You must comply with all applicable laws and avoid unauthorized activities.
                </p>

                <h3>2.3 CognideX Communications</h3>
                <p>
                    By using the Platform, you agree to receive communications from us, including promotional emails.
                </p>

                <br></br>

                <h2>3. Ownership</h2>

                <h3>3.1 CognideX Proprietary Rights</h3>
                <p>
                    The Platform and Services are the exclusive property of CognideX. All rights not expressly granted are reserved.
                </p>

                <h3>3.2 Your Data</h3>
                <p>
                    You retain ownership of the data you contribute but grant CognideX a license to use it as described in these Terms.
                </p>

                <h3>3.3 Shared Data</h3>
                <p>
                    Data shared with CognideX is anonymized and jointly owned.
                </p>

                <br></br>

                <h2>4. User Conduct</h2>

                <h3>4.1 Privacy Policy</h3>
                <p>
                    Please review our Privacy Policy, which is incorporated by reference into these Terms.
                </p>

                <h3>4.2 Acceptable Use</h3>
                <p>
                    You agree not to use the Services for illegal activities or in ways that violate these Terms.
                </p>

                <br></br>

                <h2>5. Rewards Program</h2>

                <h3>5.1 Earning CGDX</h3>
                <p>
                    You can earn CGDX through activities related to your CognideX Account, subject to CognideX’s discretion.
                </p>

                <h3>5.2 Utility Token System</h3>
                <p>
                    CGDX is the only point system within the Platform.
                </p>

                <br></br>

                <h2>6. Third-Party Services</h2>

                <h3>6.1 Third-Party Content and Links</h3>
                <p>
                    CognideX is not responsible for third-party content or links.
                </p>

                <h3>6.2 Payment Processing</h3>
                <p>
                    All cryptocurrency transactions are processed through Ethereum-compatible wallets.
                </p>

                <br></br>

                <h2>7. Confidential Information</h2>

                <h3>7.1 Confidentiality</h3>
                <p>
                    You agree to keep CognideX’s confidential information secure.
                </p>

                <h3>7.2 Press Releases</h3>
                <p>
                    You may not issue press releases or use CognideX’s trademarks without prior authorization.
                </p>

                <br></br>

                <h2>8. API Connections with Financial Institutions</h2>

                <h3>8.1 Establishment of API Connections</h3>
                <p>
                    CognideX is implementing API connections with banks, with users’ explicit consent.
                </p>

                <br></br>

                <h2>9. Retention of Personal Information</h2>

                <h3>9.1 Data Masking and Anonymization</h3>
                <p>
                    All data contributed will undergo masking and anonymization.
                </p>

                <br></br>

                <h2>10. Right to Delete Account</h2>

                <h3>10.1 User's Right to Delete Account</h3>
                <p>
                    Users can delete their CognideX Account anytime, forfeiting any accumulated CGDX.
                </p>

                <br></br>

                <h2>11. Term and Termination</h2>

                <h3>11.1 Term</h3>
                <p>
                    These Terms remain in effect while you use the Services.
                </p>

                <h3>11.2 Termination</h3>
                <p>
                    Either party may terminate these Terms with notice if the other party materially breaches them.
                </p>

                <br></br>

                <h2>12. Representation; Disclaimer of Warranties</h2>

                <h3>12.1 Authority</h3>
                <p>
                    Each party represents that it has the right to enter into these Terms.
                </p>

                <br></br>

                <h2>13. Indemnification</h2>

                <h3>13.1 Indemnification</h3>
                <p>
                    You agree to indemnify CognideX against claims arising from your use of the Services.
                </p>

                <br></br>

                <h2>14. Limitation of Liability</h2>

                <h3>14.1 Exclusion of Certain Damages</h3>
                <p>
                    CognideX is not liable for indirect or consequential damages.
                </p>

                <br></br>

                <h2>15. Governing Law; Venue; Arbitration</h2>

                <h3>15.1 Governing Law and Venue</h3>
                <p>
                    These Terms are governed by the laws of Singapore.
                </p>

                <h3>15.2 Prohibition of Class and Representative Actions</h3>
                <p>
                    You agree to bring claims only on an individual basis.
                </p>

                <br></br>

                <h2>16. General Provisions</h2>

                <h3>16.1 International Users</h3>
                <p>
                    The Services are currently available only to residents of Singapore.
                </p>

                <h3>16.2 Changes to the Service</h3>
                <p>
                    CognideX may modify the Services at any time.
                </p>

                <h3>16.3 Waiver</h3>
                <p>
                    A party's failure to enforce any provision does not waive its right to enforce it later.
                </p>

            </TermsSection>
        </FlexBackground>
    );
};

export default TermsCondition;
